<!--
 * @Author: pansp
 * @Date: 2024-12-11 09:11:07
 * @LastEditors: pansp
 * @LastEditTime: 2024-12-11 13:53:01
 * @Description: 
 * @FilePath: /social-h5-main/src/views/googlePay_page/payCourse.vue
-->
<template>
    <div>
        <!-- 导航栏 -->
        <van-sticky class="header" v-if="isShow">
            <div class="nav-bar">
                <van-icon @click="handelback" name="arrow-left" size="22px" style="font-weight: 500;" />
                <span class="nav-title">Subscription Guide</span>
                <span></span>
            </div>
        </van-sticky>
        <!-- 内容 -->
        <div class="content">
            <div class="list">
                <div style="padding-bottom: 24px;">
                    <span class="list_title">
                        1、What is the TILI Subscription?
                    </span>
                </div>
                <div class="list_content">
                    <p v-for="(item, index) in subscriptionList" :key="index" class="content_item">
                        {{ item }}
                    </p>
                    <p class="content_item">
                        Using the Google Play universal weekly subscription plan, you can pay directly via bank card.
                        Unless canceled on Google Play, the subscription will automatically renew. When you subscribe to
                        our plan using this option,
                        <span style="font-weight: 500;">
                            you can enjoy all membership benefits for the first three days for free, which includes 50
                            + 42 diamonds, a prestigious membership badge, the ability to modify your location, more
                            likes, and the ability to see who liked you and who viewed your profile.

                        </span>
                    </p>

                </div>
            </div>
            <div class="list">
                <div style="padding-bottom: 24px;">
                    <span class="list_title">
                        2、How to Subscribe?
                    </span>
                </div>
                <div style="display: flex;flex-direction: column;gap: 12px;">
                    <div v-for="(item, index) in subscribeList" :key="index" class="content_item">
                        <span class="content_title">
                            {{ item.title }}
                        </span>
                        <div v-if="item.content.length" class="subscrible_item">
                            <div v-for="(val, indx) in item.content" :key="indx">
                                <template v-if="val.type == 'text'">
                                    <p class="subscrible_text">
                                        {{ val.text }}
                                    </p>
                                </template>
                                <template v-else>
                                    <img :src="require(`@/assets/${val.text}.png`)" alt="">
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { appFunc_callExit } from '@/utils/bridge'
export default {
    data() {
        return {
            isShow: true,
            subscriptionList: [
                'By subscribing to our weekly membership, you become a VIP member of TILI, which allows you to access five premium features:',
                'a. Receive 50 diamonds weekly, with 14 diamonds daily, which can be used to unlock conversations for deeper engagement.',
                'b. Obtain a prestigious membership badge. Historical data from TILI shows that users with a membership badge are more favored by the opposite sex.',
                'c. See who has liked you and who has viewed your profile.',
                'd. Freely set your location to see if there are interesting people nearby.',
                'e. Daily allowances to like more users.',
            ],
            subscribeList: [
                {
                    title: 'a. Click “Start your free trial/subscribe.”', content: [
                        { text: 'subscribe_03', type: 'image' }
                    ]
                },
                {
                    title: 'b. If you have not linked a payment method (expected operation time: 3 minutes):', content: [
                        { text: 'b.a You can click on Vodacom Airtime Billing, then link your phone number to pay via phone bill for Google payments.', type: 'text' },
                        { text: 'b.b You can click on Credit or Debit Card, allowing you to make Google payments directly through a bank card. Currently supported cards include: Verve, Visa, MasterCard, Discover, AMEX.', type: 'text' },
                        { text: 'subscribe_05', type: 'image' },
                        { text: 'b.c After clicking Credit or Debit Card, you will enter the interface shown below. Simply fill in your bank card information truthfully, then click Continue to begin your 3-day free trial.', type: 'text' },
                        { text: 'subscribe_02', type: 'image' },
                    ]
                },
                {
                    title: 'c. If you have already linked a payment method, just click subscribe to start your 3-day free trial.(expected operation time: 30 seconds)  ', content: [
                        { text: 'subscribe_04', type: 'image' },
                    ]
                },
                {
                    title: 'd.Ensure your details are correct and tap on subscribe.', content: [

                    ]
                },
                {
                    title: 'e.Enter your password and verify to complete the process.', content: [
                        { text: 'subscribe_01', type: 'image' },
                    ]
                },
            ]
        }
    },
    methods: {
        // 返回
        handelback() {
            appFunc_callExit()
        }
    }
}
</script>
<style scoped>
.nav-bar {
    height: 44px;
    background: #ffffff;
    border-bottom: 1px solid #f2f2f6;
    display: flex;
    align-items: center;
    padding: 0 14px;
    justify-content: space-between;

    img {
        width: 30px;
        height: 30px;
        position: absolute;
        left: 5px;
    }

    .nav-title {
        font-size: 18px;
        color: #181818;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #181818;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    min-height: calc(100vh - 44px);
    padding: 24px 14px;
    background-color: #FFE4B3;
    overflow-x: hidden;

    .list_title {
        position: relative;
        font-weight: 600;
        font-size: 20px;
        color: #000000;
        line-height: 23px;
        z-index: 9;
    }

    .list_title::before {
        position: absolute;
        left: 0;
        bottom: 2px;
        content: '';
        width: 100%;
        height: 5px;
        border-radius: 34px;
        background-color: #F7B500;
        z-index: -999;
    }

    .list_content {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .subscrible_item {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 12px 0;
    }

    .content_item {
        font-weight: 400;
        font-size: 18px;
        color: #181818;
        line-height: 21px;
    }

    .content_title {
        font-size: 16px;
        color: #181818;
        line-height: 19px;
    }

    .subscrible_text {
        font-size: 14px;
        color: #3D3C3C;
        line-height: 16px;
    }

    .subscrible_content {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}
</style>